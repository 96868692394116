<template>
  <!--서브푸터 시작-->
  <div class="sub_footer_container">
    <footer>
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/common/logo_01.svg" alt="로고" />
        </router-link>
      </div>
    </footer>
  </div>
  <!--서브푸터 끝-->
</template>

<script>
export default {};
</script>

<style scroped>
.sub_footer_container {
  margin-top: 6rem;
  width: 100%;
}
.sub_footer_container footer {
  /* min-width: var(--min-width); */
  margin: 0 auto;
}
.sub_footer_container footer .logo {
  width: 25rem;
  border-top: 1px solid #ccc;
  padding: 2rem 4rem 0;
  margin: 0 auto;
}
.sub_footer_container footer .logo img {
  display: inline-block;
  padding: 1rem 1rem 0;
}
</style>