<template>
    <div class="content_container findpw changepw">
        <h1>비밀번호 변경</h1>
        <div class="con_signin">
            <div class="center">
                <div class="fill password">
                    <label for="pass">비밀번호 <span>*</span></label>
                    <input
                        type="password"
                        id="pass"
                        minlength="8"
                        required
                        placeholder="소문자+대문자+숫자+특수문자 조합 8글자 이상"
                        v-model="password"
                        @keyup.enter="passEnter"
                    />
                    <div class="passShow" @click="togglePassShow($event)">
                        <i class="far fa-eye"></i>
                        <i class="fas fa-eye-slash on"></i>
                    </div>
                </div>
                <div class="fill password">
                    <label for="pass_check">비밀번호 재확인 <span>*</span></label>
                    <input
                        type="password"
                        id="pass_check"
                        minlength="8"
                        required
                        placeholder="비밀번호를 한번 더 입력해주세요"
                        v-model="passwordRe"
                        @keyup.enter="submit"
                    />
                    <div class="passShow" @click="togglePassShow($event)">
                        <i class="far fa-eye"></i>
                        <i class="fas fa-eye-slash on"></i>
                    </div>
                </div>
                <button id="btn_signin" type="button" @click="submit">비밀번호 변경</button>
            </div>
        </div>
    </div>
    <SubFooter />
</template>

<script>
import SubFooter from "@/components/SubFooter";
import { postData } from "@/utils/postData";

export default {
    components: {
        SubFooter,
    },
    mixins: [postData],
    watch: {
        // 비밀번호 형식 확인(숫자1이상, 소문자1이상, 대문자1이상, 특수문자1이상, 최소 8글자)
        password(newPW) {
            const lower = /[a-z]/;
            const upper = /[A-Z]/;
            const number = /[0-9]/;
            const special = /[`~!@#$%^&*()_|+\-=?;:'",.<>]/;
            if (
                newPW.length >= 8 &&
                newPW.match(lower) &&
                newPW.match(upper) &&
                newPW.match(number) &&
                newPW.match(special)
            ) {
                this.passValid = true;
            } else {
                this.passValid = false;
            }
        },
    },
    data() {
        return {
            password: "",
            passwordRe: "",
            passValid: false,
        };
    },
    mounted() {
        if(document.getElementById("pass")){
            document.getElementById("pass").focus();
        }
    },
    methods: {
        // 비밀번호 입력창에서 엔터시
        passEnter() {
            document.getElementById("pass_check").focus();
        },
        // 비밀번호 '보기/가리기' 토글
        togglePassShow(event) {
            const target = event.currentTarget;
            if (target.previousElementSibling.type == "text") {
                target.previousElementSibling.type = "password";
            } else {
                target.previousElementSibling.type = "text";
            }
            target.children[0].classList.toggle("on");
            target.children[1].classList.toggle("on");
        },
        // 변경할 비밀번호 전송
        submit() {
            if (!this.password) {
                this.$store.commit("alert", "비밀번호를 입력해주세요");
            } else if (!this.passValid) {
                this.$store.commit("alert", "비밀번호 형식을 확인해주세요");
            } else if (this.passwordRe != this.password) {
                this.$store.commit("alert", "비밀번호를 재확인해주세요");
            } else {
                this.postData('/resetPass', {
                    password: this.password,
                    token: this.$route.query.token,
                }, ()=>{
                    this.$store.commit("confirm", "비밀번호가 정상적으로 변경되었습니다");
                    this.$router.push('/signin');
                })
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_login.css"></style>
